<template>
  <c-box
    w="full"
    h="full"
  >
    <CardDiet
      v-for="menu in menus"
      :key="menu.id"
      :menu="menu"
    />
  </c-box>
</template>

<script>
import CardDiet from '@/views/meal-plan/cards/card-diet.vue'
import { sortMealCategory } from '@/utils/sort-meal-category'

export default {
  name: 'AdminClientRencanaMakan',
  components: { CardDiet },
  data() {
    return {
      menus: [],
    }
  },
  computed: {
    programId() {
      return this.$route.params.programId
    },
    menus_() {
      let menus = this.menus ?? []

      if (!this.hasMenuFor('breakfast')) {
        menus.push(this.createBlankMenu('breakfast'))
      }
      if (!this.hasMenuFor('lunch')) {
        menus.push(this.createBlankMenu('lunch'))
      }
      if (!this.hasMenuFor('dinner')) {
        menus.push(this.createBlankMenu('dinner'))
      }
      if (!this.hasMenuFor('morning_snack')) {
        menus.push(this.createBlankMenu('morning_snack'))
      }
      if (!this.hasMenuFor('afternoon_snack')) {
        menus.push(this.createBlankMenu('afternoon_snack'))
      }
      if (!this.hasMenuFor('night_snack')) {
        menus.push(this.createBlankMenu('night_snack'))
      }

      menus.sort((a, b) => sortMealCategory(a.category, b.category))

      return menus
    },
  },
  async mounted() {
    this.menus = await this.axios
      .get(`/v1/admin/meal-plans/${this.programId}`)
      .then((r) => r.data.data)
  },
  methods: {
    hasMenuFor(category) {
      return this.menus.find((it) => it.category === category)
    },
    createBlankMenu(category) {
      return {
        id: 'category_' + category,
        timeStart: '00:00:00',
        timeEnd: '00:00:00',
        category: category,
        caloriesNeed: 0,
        totalCalories: 0,
        totalPortion: 0,
        createdAt: null,
        updatedAt: null,
        nutritionist: {},
        meals: [],
      }
    },
  },
}
</script>
