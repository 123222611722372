<template>
  <c-box>
    ProfileGizi
    <c-flex
      flex="1"
      flex-dir="column"
      px="20px"
    >
      <c-flex
        v-if="!isEmpty"
        v-chakra="{ gap: '10px' }"
        mt="10px"
        align-items="center"
      >
        <c-image
          h="24px"
          w="24px"
          :src="require('@/assets/icon-user-filled.svg')"
          alt="icon user"
        />
        <c-text
          font-size="12px"
          font-weight="400"
          color="gray.900"
        >
          Dibuat oleh {{ nutritionistName }} - Ahli gizi Dietela
        </c-text>
      </c-flex>
      <c-text
        mt="15px"
        font-size="16px"
        font-weight="700"
      >
        Kondisi Giziku
      </c-text>
      <c-box v-if="isKondisiGizikuEmpty">
        <EmptyState title="Kondisi giziku belum terisi" />
      </c-box>
      <template v-else>
        <c-flex
          mt="15px"
          flex-dir="column"
          border="1px solid #C4C4C4"
          border-radius="8px"
          p="8px"
        >
          <c-flex
            v-chakra="{ gap: '10px' }"
            align-items="center"
          >
            <c-text
              font-size="12px"
              font-weight="400"
            >
              Indeks Masa Tubuh
            </c-text>
            <c-image
              h="18px"
              w="18px"
              :src="require('@/assets/icon-info-circle.svg')"
              alt="icon info"
            />
          </c-flex>
          <c-text
            mt="5px"
            font-size="16px"
            font-weight="700"
          >
            {{ bmi }}kg/m2
          </c-text>
          <c-text
            mt="5px"
            font-size="14px"
            font-weight="400"
            color="#555"
          >
            Status gizi mu: {{ statusGizi }}
          </c-text>
          <ProgressComp
            v-chakra
            :value="progressValue"
            mt="10px"
          />
        </c-flex>
        <c-flex
          v-if="profileGizi"
          v-chakra="{ gap: '8px' }"
          mt="10px"
          overflow-x="auto"
        >
          <c-flex
            flex-dir="column"
            border="1px solid #c4c4c4"
            border-radius="8px"
            p="8px"
            flex-shrink="0"
          >
            <c-text
              font-size="12px"
              font-weight="400"
              color="#4F4F4F"
            >
              Umur
            </c-text>
            <c-text
              font-size="16px"
              font-weight="700"
              color="#111"
            >
              {{ profileGizi.age }} Tahun
            </c-text>
          </c-flex>
          <c-flex
            flex-dir="column"
            border="1px solid #c4c4c4"
            border-radius="8px"
            p="8px"
            flex-shrink="0"
          >
            <c-text
              font-size="12px"
              font-weight="400"
              color="#4F4F4F"
            >
              Berat badan/Tinggi badan
            </c-text>
            <c-text
              font-size="16px"
              font-weight="700"
              color="#111"
            >
              {{ profileGizi.weight | toFixed }}kg /
              {{ profileGizi.height | toFixed }}cm
            </c-text>
          </c-flex>
          <c-flex
            flex-dir="column"
            border="1px solid #c4c4c4"
            border-radius="8px"
            p="8px"
            flex-shrink="0"
          >
            <c-text
              font-size="12px"
              font-weight="400"
              color="#4F4F4F"
            >
              BB ideal
            </c-text>
            <c-text
              font-size="16px"
              font-weight="700"
              color="#111"
            >
              {{ profileGizi.idealWeightMinimum | toFixed }} -
              {{ profileGizi.idealWeightMaximum | toFixed }} kg
            </c-text>
          </c-flex>
        </c-flex>
      </template>

      <c-flex
        my="10px"
        align-items="center"
        justify="space-between"
      >
        <c-text
          mt="10px"
          font-size="16px"
          font-weight="700"
          color="#333"
        >
          Asupan Gizi
        </c-text>
      </c-flex>
      <c-box v-if="isAsupanGiziEmpty">
        <EmptyState title="Asupan gizi belum terisi" />
      </c-box>
      <c-flex
        v-else
        :flex-dir="['column', 'row']"
        flex-wrap="wrap"
        grid-gap="10px"
      >
        <card-kalori
          v-for="asupan in _asupanGizi"
          :key="asupan.title"
          v-chakra
          :title="asupan.title"
          :point-sekarang="asupan.pointSekarang"
          :point-kebutuhan="asupan.pointKebutuhan"
          :warning-text="asupan.catatan"
          :satuan="asupan.title === 'Energi' ? 'kalori':'gram'"
          :w="['auto', '380px']"
        />
      </c-flex>

      <card-kondisi-tubuh
        v-chakra
        :kondisi-tubuh="kondisiTubuh"
        mt="20px"
      />
      <card-saran
        v-if="saran != null"
        v-chakra
        :saran="saran"
        mt="20px"
      />
    </c-flex>
  </c-box>
</template>

<script>
import ProgressComp from '@/views/meal-plan/ahli-gizi/progress.vue'
import CardKalori from '@/views/meal-plan/ahli-gizi/card-kalori.vue'
import CardKondisiTubuh from '@/views/meal-plan/ahli-gizi/card-kondisi-tubuh.vue'
import CardSaran from '@/views/meal-plan/ahli-gizi/card-saran.vue'
import EmptyState from '@/views/meal-plan/ahli-gizi/empty-state.vue'

export default {
  name: 'AdminClientProfileGizi',
  components: {
    ProgressComp,
    CardKalori,
    CardKondisiTubuh,
    CardSaran,
    EmptyState,
  },
  data() {
    return {
      profileGizi: null,
      asupanGizi: null,
      kondisiTubuh: null,
      saran: null,
    }
  },
  computed: {
    programId() {
      return this.$route.params.programId
    },
    nutritionistName() {
      return 'Someone'
    },
    progressValue() {
      let min = 18
      let max = 35
      let cur = this.bmi
      // let min = this.profileGizi?.idealWeightMinimum
      // let max = this.profileGizi?.idealWeightMaximum
      // let cur = this.profileGizi?.weight

      let progress = ((cur - min) * 100) / (max - min)

      // to make progress value stay inside it's box
      if (progress >= 100) progress = 99
      if (progress < 0) progress = 1

      return progress
    },
    bmi() {
      return this.profileGizi?.bodyMassIndex
    },
    statusGizi() {
      // Data based on https://www.omnicalculator.com/health/bmi#bmi-ranges
      if (this.bmi < 18.5) return 'Kurang Gizi'
      if (this.bmi > 18.5 && this.bmi < 25) return 'Normal'
      if (this.bmi > 25 && this.bmi < 30) return 'Preobesitas'
      if (this.bmi > 30) return 'Obesitas'
      return 'Obsesitas'
    },
    _asupanGizi() {
      if (this.asupanGizi == null) return []
      return [
        {
          title: 'Energi',
          pointSekarang: this.asupanGizi.currentEnergyIntake,
          pointKebutuhan: this.asupanGizi.energyIntakeNeeds,
          catatan: this.asupanGizi.energyIntakeNotes,
        },
        {
          title: 'Protein',
          pointSekarang: this.asupanGizi.currentProteinIntake,
          pointKebutuhan:
            this.asupanGizi.proteinIntakeNeedPerWeight *
            (this.profileGizi?.weight ?? 1),
          catatan: this.asupanGizi.proteinIntakeNotes,
        },
        {
          title: 'Lemak',
          pointSekarang: this.asupanGizi.currentFatIntake,
          pointKebutuhan: this.asupanGizi.fatIntakeNeeds,
          catatan: this.asupanGizi.fatIntakeNotes,
        },
        {
          title: 'Karbohidrat',
          pointSekarang:
            this.asupanGizi.currentCarbohydrateIntake ??
            this.asupanGizi.currentcarbohydrateIntake,
          pointKebutuhan: this.asupanGizi.carbohydrateIntakeNeeds,
          catatan: this.asupanGizi.carbohydrateIntakeNotes,
        },
        {
          title: 'Serat',
          pointSekarang: this.asupanGizi.currentFiberIntake,
          pointKebutuhan: this.asupanGizi.fiberIntakeNeeds,
          catatan: this.asupanGizi.fiberIntakeNotes,
        },
      ]
    },
    isAsupanGiziEmpty() {
      return Object.keys(this.asupanGizi ?? {}).length === 0
    },
    isKondisiGizikuEmpty() {
      return this.bmi === '0.00' || this.bmi == null
    },
    isEmpty() {
      return this.isAsupanGiziEmpty && this.isKondisiGizikuEmpty
    },
  },
  async mounted() {
    this.profileGizi = await this.axios
      .get(`/v1/admin/nutrition-profile/${this.programId}`)
      .then((r) => r.data.data)
    this.asupanGizi = await this.axios
      .get(`/v1/admin/nutrition-intake/${this.programId}`)
      .then((r) => r.data.data)
    this.kondisiTubuh = await this.axios
      .get(`/v1/admin/body-proportion-lifestyle/${this.programId}`)
      .then((r) => r.data.data)
    this.saran = await this.axios
      .get(`/v1/admin/nutrition-lifestyle-advice/${this.programId}`)
      .then((r) => r.data.data)
  },
}
</script>
