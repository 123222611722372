<template>
  <c-box>
    <c-flex
      mt="15px"
      overflow-x="auto"
      align-items="center"
      justify-content="space-around"
    >
      <BadgeWeek
        v-for="(week, index) in weeksRange"
        :key="week"
        v-chakra
        :selected="week === +($route.query.day || 1)"
        :week="week"
        :ml="index === 0 ? '' : '10px'"
        @click="
          $router.replace({
            query: { ...$route.query, day: week },
          })
        "
      />
    </c-flex>
    <c-flex
      mt="15px"
      flex-dir="column"
    >
      <card-menu
        :menus="_menus"
        @select-menu="selectedRencanaMakan = $event"
      />
    </c-flex>
    <Portal
      v-if="selectedRencanaMakan"
      to="main"
    >
      <c-box
        top="0"
        left="0"
        height="100vh"
        width="100vw"
        position="fixed"
        z-index="1001"
      >
        <c-box
          position="absolute"
          top="0"
          left="0"
          height="100vh"
          width="100vw"
          bg="#333c"
          @click.stop.prevent="selectedRencanaMakan = null"
        />
        <c-flex
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bg="white"
          rounded="md"
          flex-dir="column"
          min-w="700px"
          max-h="600px"
        >
          <c-flex p="20px">
            <c-button
              variant="ghost"
              h="24px"
              w="24px"
              min-w="0"
              p="0"
              m="0"
              @click.prevent.stop="selectedRencanaMakan = null"
            >
              <c-icon
                name="close"
                size="14px"
                color="#333"
              />
            </c-button>
            <c-text ml="15px">
              Detail menu
            </c-text>
          </c-flex>
          <c-flex
            overflow-y="auto"
            overflow-x="hidden"
            px="35px"
            py="10px"
            grid-gap="35px"
          >
            <template v-for="meal in selectedRencanaMakan.meals">
              <c-box
                :key="meal.id"
                w="72px"
                h="auto"
                flex-dir="column"
                align-items="center"
                justify-content="flex-start"
                font-weight="initial"
              >
                <c-image
                  :src="meal.mealIngredientNameGlossary.photoUrl"
                  :alt="meal.mealIngredientNameGlossary.ingredientName"
                  h="72px"
                  w="72px"
                  object-fit="cover"
                  box-shadow="0px 0px 8px 5px rgba(0, 0, 0, 0.12)"
                  border-radius="8px"
                />
                <c-text
                  v-chakra="{
                    'word-wrap': 'anywhere',
                  }"
                  m="0"
                  mt="10px"
                  font-size="12px"
                  text-align="center"
                  color="#333333"
                  max-w="100%"
                  white-space="break-spaces"
                  flex="1"
                >
                  {{ meal.mealIngredientNameGlossary.ingredientName }}
                </c-text>
                <c-text
                  p="0"
                  mt="2px"
                  font-size="12px"
                  text-align="center"
                  color="#888888"
                >
                  {{ meal.dose }} {{ meal.doseUnit }}
                </c-text>
              </c-box>
            </template>
          </c-flex>
          <c-button
            type="button"
            variant="outline"
            variant-color="primary"
            m="20px"
            rounded="full"
            as="router-link"
            :to="{ name: 'glossaries' }"
          >
            Lihat glossary foto makan
          </c-button>
        </c-flex>
      </c-box>
    </Portal>
  </c-box>
</template>

<script>
import BadgeWeek from '@/views/meal-plan/menu-recommendation/badge-week.vue'
import CardMenu from '@/views/meal-plan/menu-recommendation/card-menu.vue'
import getMenuCategory from '@/utils/get-menu-category'

import dayjs from 'dayjs'
import parseFormat from 'dayjs/plugin/customParseFormat'
import isToday from 'dayjs/plugin/isToday'
import { sortMealCategory } from '@/utils/sort-meal-category'

let day = dayjs.extend(parseFormat).extend(isToday)

export default {
  name: 'AdminClientRekomendasiMenu',
  components: { BadgeWeek, CardMenu },
  data() {
    return {
      selectedRencanaMakan: null,
      rencanaMakan: [],
      menus: [],
    }
  },
  computed: {
    programId() {
      return this.$route.params.programId
    },
    weeksRange() {
      return Array.from({ length: 10 }, (_, index) => index + 1)
    },
    day() {
      return +(this.$route.query.day ?? 1)
    },
    listingMealsPlanData() {
      let prefix =
        this.isRoleSuperAdmin ? 'super-admin' : 'admin'
      return [prefix, this.clientId, this.programId, this.day]
    },
    _menus() {
      return [...this.rencanaMakan]
        .sort((a, b) => sortMealCategory(a.category, b.category))
        .map((menu) => {
          let timeStart = day(menu.timeStart, 'HH:mm:ss').format('HH A')
          let timeEnd = day(menu.timeEnd, 'HH:mm:ss').format('HH A')

          return {
            ...menu,
            title: getMenuCategory(menu.category),
            time: `${timeStart} - ${timeEnd}`,
            meals: this.menus
              .filter((it) => it.mealPlansId === menu.id)
              .flatMap((it) => it.meals)
              .filter((it) => it != null)
              .map((it) => ({
                ...it,
                text: `${it.dose} ${it.doseUnit} ${it.foodIngredient}`,
              })),
          }
        })
    },
  },
  watch: {
    listingMealsPlanData: {
      immediate: true,
      async handler([prefix, , programId, day]) {
        this.rencanaMakan = await this.axios
          .get(`/v1/${prefix}/meal-plans/${this.programId}`)
          .then((r) => r.data.data)
        this.menus = await this.axios
          .get(`/v1/${prefix}/meals-recommendation/${programId}/${day}`)
          .then((r) => r.data.data)
      },
    },
  },
}
</script>
